import { PageProps } from "gatsby";
import React from "react";

import SheetMusic from "../../components/pages/SheetMusic/SheetMusic";

import { dnProps } from "../../js/utils";

const SheetMusicPage = (props: PageProps) => (
  <SheetMusic {...{ ...dnProps, ...props }} />
);

export default SheetMusicPage;
